import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import CustomLink from '../../common/CustomLink/CustomLink';
import { useScreenSizeContext } from '../../core/context/screenSize.context';
import { shuffle } from '../../core/helpers';
import { ILink, Member } from '../../core/models';
import './PeopleShowcase.css';
import PeopleShowcaseItem from './PeopleShowcaseItem/PeopleShowcaseItem';

interface IItem {
  user_id: Member;
  label: string;
}

interface IProps {
  title: string;
  cta: ILink;
  peopleShowcaseItems: IItem[];
}

function PeopleShowcase({ title, cta, peopleShowcaseItems }: IProps) {
  const { isTablet, isMobile } = useScreenSizeContext();
  const shuffledItems = useMemo(() => shuffle(peopleShowcaseItems), [peopleShowcaseItems]);

  const mainItem = useMemo(() => {
    if (!shuffledItems?.length) return null;
    const item: IItem = shuffledItems[0];

    if (!item) return null;
    return item.user_id.platform_private ? (
      <div className="people-showcase__main flex gap-md py-sm px-sm">
        <PeopleShowcaseItem item={item} main />
      </div>
    ) : (
      <Link
        className="people-showcase__main flex gap-md py-sm px-sm"
        to={`/community/people/${item.user_id.slug}` || '/community'}
        target="_blank"
        key={item.user_id._id}
      >
        <PeopleShowcaseItem item={item} main />
      </Link>
    );
  }, [shuffledItems]);

  const firstGroup = useMemo(() => {
    if (!shuffledItems?.length) return null;
    const items: IItem[] = shuffledItems.slice(1, 7);
    if (!items.length) return null;

    return items.map((item) =>
      item.user_id.platform_private ? (
        <div className="people-showcase__item flex gap-sm">
          <PeopleShowcaseItem item={item} />
        </div>
      ) : (
        <Link
          className="people-showcase__item flex gap-sm"
          to={`/community/people/${item.user_id.slug}` || '/community'}
          target="_blank"
          key={item.user_id._id}
        >
          <PeopleShowcaseItem item={item} />
        </Link>
      ),
    );
  }, [shuffledItems]);

  const lastGroup = useMemo(() => {
    if (!shuffledItems?.length) return null;
    const items: IItem[] = shuffledItems.slice(7, isTablet || isMobile ? 11 : 12);
    if (!items.length) return null;

    return items.map((item) =>
      item.user_id.platform_private ? (
        <div className="people-showcase__item flex gap-sm">
          <PeopleShowcaseItem item={item} />
        </div>
      ) : (
        <Link
          className="people-showcase__item flex gap-sm"
          to={`/community/people/${item.user_id.slug}` || '/community'}
          target="_blank"
          key={item.user_id._id}
        >
          <PeopleShowcaseItem item={item} />
        </Link>
      ),
    );
  }, [shuffledItems, isTablet, isMobile]);

  return (
    <section className="people-showcase section">
      <div className="wrap">
        <div className="people-showcase__content flex gap-lg">
          <h2 className="people-showcase__title h2">{title}</h2>
          {!peopleShowcaseItems.length ? null : (
            <div className="people-showcase__container gap-md">
              <div className="people-showcase__first-container gap-md">{firstGroup}</div>
              {mainItem}
              <div className="people-showcase__last-container gap-md">{lastGroup}</div>
            </div>
          )}
          {!cta ? null : <CustomLink className="people-showcase__cta" link={cta} />}
        </div>
      </div>
    </section>
  );
}

export default PeopleShowcase;
