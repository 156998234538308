import React, { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import senitize from 'rehype-sanitize';

import './Programs.css';
import ProgramsItem, { IProgramsItemProps } from './ProgramsItem/ProgramsItem';

interface IProps {
  title: string;
  desc?: string;
  programsItems: IProgramsItemProps[];
  slug: string;
}

function Programs({ title, desc, programsItems, slug }: IProps) {
  const cards = useMemo(
    // eslint-disable-next-line react/jsx-props-no-spreading
    () => programsItems?.map((item) => <ProgramsItem {...item} key={item.title} slug={slug} />),
    [programsItems, slug],
  );

  return (
    <section className="programs section-bg section-bg--grey">
      <div className="wrap flex gap-lg">
        <div className="programs__header flex flex--center gap-md">
          <h2 className="programs__title h2">{title}</h2>
          {!desc ? null : (
            <ReactMarkdown
              className="programs__desc markdown body-large"
              rehypePlugins={[rehypeRaw, senitize]}
              components={{
                p: React.Fragment,
              }}
            >
              {desc}
            </ReactMarkdown>
          )}
        </div>
        <div className="programs__container gap-lg">{cards}</div>
      </div>
    </section>
  );
}

export default Programs;
