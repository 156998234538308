import CustomLink from '../../../common/CustomLink/CustomLink';
import { Icon } from '../../../common/Icon/Icon';
import Image from '../../../common/Image/Image';
import { IImage, ILink } from '../../../core/models';
import { IForm } from '../../../core/models/form';
import HubSpotFromModal from '../../HubSpotFromModal/HubSpotFromModal';

export interface IEventItem {
  title: string;
  slug: string;
  desc?: string;
  location?: string;
  price?: string;
  date?: number;
  img?: IImage;
  cta?: ILink;
  form?: IForm;
}

function EventItem({ title, slug, desc, location, price, date, img, cta, form }: IEventItem) {
  const dateObj = date ? new Date(date) : null;
  const day = dateObj?.toLocaleString('en-US', { day: '2-digit' });
  const month = dateObj?.toLocaleString('en-US', { month: 'short' });

  return (
    <div className="event-item">
      {!date ? null : (
        <div className="event-item__date">
          <div className="h4">{day}</div>
          <div className="body-small">{month}</div>
        </div>
      )}
      {!img ? null : (
        <div className="event-item__img-container">
          <Image className="cover-img" img={img} size="small" />
        </div>
      )}
      <div>
        <h3 className="event-item__title h3">{title}</h3>
        {!desc ? null : <div className="event-item__desc body-small">{desc}</div>}
      </div>
      {!location && !price ? null : (
        <div className="event-item__info-container">
          {!location ? null : (
            <div className="event-item__info body-small">
              <Icon.Location className="event-item__icon" />
              {location}
            </div>
          )}
          {!price ? null : (
            <div className="event-item__info body-small">
              <Icon.Tag className="event-item__icon" />
              {price}
            </div>
          )}
        </div>
      )}
      {!cta?.href ? null : <CustomLink className="event-item__cta" link={cta} />}
      <HubSpotFromModal form={form} slug={slug} />
    </div>
  );
}

export default EventItem;
