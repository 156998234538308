import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import placeholder from '../../../assets/placeholder.png';
import Image from '../../../common/Image/Image';
import { slugify } from '../../../core/helpers';
import { Member } from '../../../core/models';

interface IProps {
  item: {
    user_id: Member;
    label: string;
  };
  title: string;
}

function SimpleSliderItem({ item, title }: IProps) {
  const card = useMemo(
    () => (
      <div className="simple-slider__slide-card gap-sm">
        <div className="simple-slider__slide-img-container" style={{ background: 'white' }}>
          {item.user_id.profile_pic ? (
            <Image
              className="simple-slider__slide-img cover-img"
              img={item.user_id.profile_pic}
              size="small"
            />
          ) : (
            <img
              className="simple-slider__slide-img cover-img"
              src={placeholder}
              alt="THNK member"
            />
          )}
        </div>
        <div>
          <h3 className="simple-slider__slide-title h3">
            {`${item.user_id.firstname} ${item.user_id.lastname}`}
          </h3>
          {!item.user_id.title ? null : (
            <div className="simple-slider__slide-subtitle truncate l-2 body-large">
              {item.user_id.title}
            </div>
          )}
        </div>
      </div>
    ),
    [item.user_id.firstname, item.user_id.lastname, item.user_id.profile_pic, item.user_id.title],
  );

  return item.user_id.platform_private ? (
    card
  ) : (
    <Link
      className="simple-slider__slide-link gap-sm"
      to={`/community/people/${item.user_id.slug}` || '/community'}
      target="_blank"
      id={`${slugify(title)}-${item.user_id.slug}`}
    >
      {card}
    </Link>
  );
}

export default SimpleSliderItem;
