import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import senitize from 'rehype-sanitize';

import CustomLink from '../../../common/CustomLink/CustomLink';
import Image from '../../../common/Image/Image';
import { IImage, ILink } from '../../../core/models';
import HubSpotFromModal from '../../HubSpotFromModal/HubSpotFromModal';

export interface IProgramsItemProps {
  title: string;
  desc?: string;
  img?: IImage;
  link?: ILink;
  form: {
    portalId?: string;
    formId?: string;
    region?: string;
    id?: string;
    btnLabel?: string;
  };
  slug: string;
}

function ProgramsItem({ title, desc, img, link, form, slug }: IProgramsItemProps) {
  return (
    <div className="programs__item py-md px-md" key={title}>
      <div className="programs__item-container flex gap-lg">
        <div className="programs__item-content flex gap-md">
          {!img ? null : (
            <div className="programs__item-img-container">
              <Image className="cover-img" img={img} size="medium" />
            </div>
          )}
          <h3 className="programs__item-title h2">
            <ReactMarkdown
              className="markdown"
              rehypePlugins={[rehypeRaw, senitize]}
              components={{
                p: React.Fragment,
                em: 'u',
              }}
            >
              {title}
            </ReactMarkdown>
          </h3>
          {!desc ? null : (
            <ReactMarkdown
              rehypePlugins={[rehypeRaw, senitize]}
              className="programs__item-desc markdown body-large"
              components={{
                em: 'span',
              }}
            >
              {desc}
            </ReactMarkdown>
          )}
        </div>
        <div className="programs__item-footer gap-sm">
          <HubSpotFromModal form={form} slug={slug} />
          {!link ? null : <CustomLink className="programs__item-cta" link={link} />}
        </div>
      </div>
    </div>
  );
}

export default ProgramsItem;
