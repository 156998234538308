/* eslint-disable react/jsx-props-no-spreading */
import EventItem, { IEventItem } from './EventItem/EventItem';
import './Events.css';

interface IUProps {
  title: string;
  background: 'white' | 'grey';
  items: IEventItem[];
  slug: string;
}

function Events({ title, background, items, slug }: IUProps) {
  return (
    <section
      className={`events${background === 'grey' ? ' section-bg section-bg--grey' : ' section'}`}
    >
      <div className="wrap">
        <h2 className="events__title h2">{title}</h2>
        <div className="events__item-container">
          {items.map((item) => (
            <EventItem {...item} slug={slug} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Events;
