import { useMemo, useRef, useState } from 'react';
import { A11y, Mousewheel, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/a11y';
import { Swiper, SwiperSlide } from 'swiper/react';

import CustomButton from '../../common/CustomButton/CustomButton';
import CustomLink from '../../common/CustomLink/CustomLink';
import { Icon } from '../../common/Icon/Icon';
import { useScreenSizeContext } from '../../core/context/screenSize.context';
import { slugify } from '../../core/helpers';
import { ILink, Member } from '../../core/models';
import './SimpleSlider.css';
import SimpleSliderItem from './SimpleSliderItem/SimpleSliderItem';

interface IProps {
  title: string;
  simpleSliderItems: {
    user_id: Member;
    label: string;
  }[];
  cta?: ILink;
  darkText?: boolean;
}

export function SimpleSlider({ title, simpleSliderItems, cta, darkText }: IProps) {
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [init, setInit] = useState(false);
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const { isMobile } = useScreenSizeContext();

  const slides = useMemo(
    () =>
      simpleSliderItems?.map((item) => (
        <SwiperSlide className="simple-slider__slide" key={item.user_id._id}>
          <SimpleSliderItem item={item} title={title} />
        </SwiperSlide>
      )),
    [simpleSliderItems, title],
  );

  return (
    <section className="simple-slider section" style={{ color: darkText ? 'var(--text)' : '' }}>
      <div className="wrap">
        <div className="simple-slider__container gap-lg">
          <h2 className="simple-slider__title h2">{title}</h2>
          <Swiper
            className={`simple-slider__slider${isLocked ? ' locked' : ''}`}
            slidesPerView="auto"
            watchOverflow
            modules={[Navigation, Mousewheel, A11y]}
            grabCursor
            mousewheel={{
              forceToAxis: true,
            }}
            spaceBetween={isMobile ? 24 : 40}
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
            onSwiper={() => setInit(true)}
            onLock={() => setIsLocked(true)}
            onUnlock={() => setIsLocked(false)}
          >
            {slides}
          </Swiper>
          <div className="simple-slider__footer">
            {!cta ? null : <CustomLink className="simple-slider__cta" link={cta} />}
            <div className="simple-slider__nav slider__nav" hidden={isLocked}>
              <CustomButton
                className="slider__arrow slider__arrow--prev"
                id={`${slugify(title)}-prev`}
                type="button"
                ref={prevRef}
              >
                <span className="sr-only">Previous slide</span>
                <Icon.Chevron className="icon" />
              </CustomButton>
              <CustomButton
                className="slider__arrow slider__arrow--next"
                id={`${slugify(title)}-next`}
                type="button"
                ref={nextRef}
              >
                <span className="sr-only">Next slide</span>
                <Icon.Chevron className="icon" />
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
