/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import useFetch from '../hooks/useFetch';

interface IProps {
  children: JSX.Element;
}

interface IData {
  [key: string]: any;
}

const PAGE_URL = `${process.env.REACT_APP_API_URL}/page`;

const DataContext = createContext<any>(null);

export const useDataContext = () => useContext(DataContext);

export function DataContextProvider({ children }: IProps) {
  const { result: generalData, doFetch: fetchGeneralData } = useFetch(
    `${PAGE_URL}?slug=general-data`,
  );
  const [page, setPage] = useState('');
  const { doFetch: fetchPageData, error } = useFetch(
    `${PAGE_URL}?slug=${
      page.includes('.') ? `${page.split('.')[1]}&parent=${page.split('.')[0]}` : page
    }&populate=user_id&populate=img&populate=article&populate=profile_pic&populate=authors&populate=tags&populate=case`,
  );

  const [data, setData] = useState<IData>({
    generalData: {
      navigation: null,
      legal: {},
      footer: {},
      contact: {},
    },
  });

  useEffect(() => {
    fetchGeneralData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!generalData) return;
    setData((prevState) => ({
      ...prevState,
      generalData: generalData.data.body,
    }));
  }, [generalData]);

  useEffect(() => {
    if (data[page] || !page) return;
    const fetchData = async () => {
      const res = await fetchPageData();
      if (res === 'aborted') return;
      if (res?.status === 'success') {
        setData((prevState) => ({
          ...prevState,
          [page]: { ...res.data.body, type: res.data.type },
        }));
      } else {
        setData((prevState) => ({
          ...prevState,
          [page]: 'Page not found',
        }));
      }
    };

    fetchData();
  }, [data, fetchPageData, page]);

  const value = useMemo(
    () => ({
      data,
      setPage,
      error,
    }),
    [data, error],
  );

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
}
