import placeholder from '../../../assets/placeholder.png';
import Image from '../../../common/Image/Image';
import LineClamp from '../../../common/LineClamp/LineClamp';
import { Member } from '../../../core/models';

interface IProps {
  item: {
    user_id: Member;
    label: string;
  };
  main?: boolean;
}

function PeopleShowcaseItem({ item, main }: IProps) {
  return (
    <>
      <div className="people-showcase__img-container">
        {item.user_id.profile_pic ? (
          <Image className="cover-img" img={item.user_id.profile_pic} size="medium" />
        ) : (
          <img className="cover-img" src={placeholder} alt="THNK member" />
        )}
      </div>
      <div>
        <h3 className={`people-showcase__item-title${main ? ' h2' : ' h3'}`}>
          {item.user_id.firstname} {item.user_id.lastname}
        </h3>
        {!item.user_id.title ? null : (
          <div className="people-showcase__expertise truncate l-2 body-large">
            {item.user_id.title}
          </div>
        )}
      </div>
      {!item.user_id.bio || !main ? null : (
        <LineClamp className="people-showcase__main-desc body-large" lines={6}>
          {item.user_id.bio}
        </LineClamp>
      )}
    </>
  );
}

export default PeopleShowcaseItem;
